import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { gql, useApolloClient, useLazyQuery } from '@apollo/client'
import Header from '../components/Headers/Header'
import CustomLoader from '../components/Loader/CustomLoader'
import { GetReferralsHistoriesByCodeStatus } from '../apollo'
import DataTable from 'react-data-table-component'
import { customStyles } from '../utils/tableForReferralHistory'
import {
  Container,
  Button,
  Grid,
  Box,
  Typography,
  TextField,
  MenuItem
} from '@mui/material'
import { Icon } from '@iconify/react'
import useGlobalStyles from '../utils/globalStyles'
import TableHeader from '../components/TableHeader'

// GraphQL queries
const GET_REFERRAL_HISTORY = gql`
  ${GetReferralsHistoriesByCodeStatus}
`

const ReferralHistory = props => {
  const { t } = props
  const globalClasses = useGlobalStyles()
  const [referralData, setReferralData] = useState([])
  const [code, setCode] = useState('')
  const [status, setStatus] = useState(0)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [endDateError, setEndDateError] = useState('')

  const [fetchReferralHistory, { loading }] = useLazyQuery(
    GET_REFERRAL_HISTORY,
    {
      onCompleted: data => {
        setReferralData(
          data.getReferralsHistoriesByCodeStatus.referrelsHistory || []
        )
      },
      onError: error => {
        console.error('Error fetching referral history:', error)
      }
    }
  )

  const formatDateToISO = dateString => {
    const date = new Date(dateString)
    return date.toISOString().replace('Z', '+00:00')
  }

  const handleFetchStats = () => {
    if (!code || !startDate || !endDate) {
      alert(
        'Please provide all required fields: Code, Start Date, and End Date.'
      )
      return
    }

    const formattedStartDate = formatDateToISO(startDate)
    const formattedEndDate =
      formatDateToISO(endDate).split('T')[0] + 'T23:59:59.999+00:00'

    fetchReferralHistory({
      variables: {
        code,
        status: parseInt(status, 10),
        start: formattedStartDate,
        end: formattedEndDate,
        skip: 0,
        limit: 100
      }
    })
  }

  const handleStartDateChange = newStartDate => {
    setStartDate(newStartDate)
    if (endDate && new Date(newStartDate) > new Date(endDate)) {
      setEndDate('')
    }
  }

  const handleEndDateChange = newEndDate => {
    if (new Date(newEndDate) < new Date(startDate)) {
      setEndDateError('End date cannot be earlier than start date.')
    } else {
      setEndDateError('')
      setEndDate(newEndDate)
    }
  }
  
  const formatDate = dateString => {
    if (!dateString) return 'N/A' // Handle cases where the date might be missing
    const date = new Date(dateString)
    return date.toLocaleDateString() // Format the date to exclude the timestamp
  }


  const columns = [
    { name: 'Sl No.', cell: (row, index) => <>{index + 1}</>, sortable: false,style: { cursor: 'pointer', },wrap: true,
  },
    { name: 'Code', selector: 'code', sortable: true,style: { cursor: 'pointer', minWidth: '200px' },wrap: true,},
    { name: 'Referrer Name', selector: 'referrerName', sortable: true,style: { cursor: 'pointer', minWidth: '150px' },wrap: true, },
    { name: 'Referrer Phone', selector: 'referrerPhone', sortable: true,style: { cursor: 'pointer', minWidth: '150px' },wrap: true, },
    { name: 'Referee Name', selector: 'refereeName', sortable: true,style: { cursor: 'pointer', minWidth: '150px' },wrap: true, },
    { name: 'Referee Phone', selector: 'refereePhone', sortable: true,style: { cursor: 'pointer', minWidth: '150px' },wrap: true,},
    {
      name: 'Order ID',
      selector: 'orderId',
      sortable: true,
      wrap: true,
      style: { cursor: 'pointer', minWidth: '150px'  }
    },
    {
      name: 'Applied Date',
      selector: row => formatDate(row.appliedDate),
      sortable: true,
      wrap: true,
      style: { cursor: 'pointer', minWidth: '150px' }
    },
    {
      name: 'Registered Date',
      selector: row => formatDate(row.registeredDate),
      sortable: true,
      style: { cursor: 'pointer', minWidth: '150px' }
    },
    {
      name: 'Redeemed Date',
      selector: row => formatDate(row.redeemedDate),
      sortable: true,
      style: { cursor: 'pointer', minWidth: '150px' }
    },
    {
      name: '% Applied',
      selector: 'percentageApplied',
      sortable: true,
      style: { cursor: 'pointer', minWidth: '150px' }
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      style: { cursor: 'pointer', minWidth: '150px' },
      cell: row => {
        // Mapping status number to text
        const statusMap = {
          0: 'ALL',
          1: 'REGISTERED',
          2: 'APPLIED',
          3: 'REDEEMED'
        }
        return statusMap[row.status] || 'Unknown'
      }
    }
    
  ]

  const statusOptions = [
    { value: 0, label: 'ALL' },
    { value: 1, label: 'REGISTERED' },
    { value: 2, label: 'APPLIED' },
    { value: 3, label: 'REDEEMED' }
  ]

  return (
    <>
      <Header />
      <Container className={globalClasses.flex}>
        <Box sx={{ mb: 3, mt: 3 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}>
              <TextField
                label="Referral Code"
                value={code}
                onChange={e => setCode(e.target.value)}
                InputProps={{
                  style: { color: 'black' }
                }}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                select
                label="Status"
                value={status}
                onChange={e => setStatus(e.target.value)}
                InputProps={{
                  style: { color: 'black' }
                }}
                sx={{ width: '100%' }}>
                {statusOptions.map(option => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    sx={{ color: 'black' }}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Start Date"
                type="date"
                value={startDate}
                onChange={e => handleStartDateChange(e.target.value)}
                InputProps={{
                  style: { color: 'black' }
                }}
                InputLabelProps={{
                  shrink: true
                }}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="End Date"
                type="date"
                value={endDate}
                onChange={e => handleEndDateChange(e.target.value)}
                error={!!endDateError}
                helperText={endDateError}
                InputProps={{
                  style: { color: 'black' }
                }}
                InputLabelProps={{
                  shrink: true
                }}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                onClick={handleFetchStats}
                className={globalClasses.dashbordBtn}>
                <Icon icon="ic:twotone-plus" style={{ color: 'white' }} /> Fetch
                Referrals
              </Button>
            </Grid>
          </Grid>
        </Box>
        {loading ? (
          <CustomLoader />
        ) : (

          <div style={{ overflowX: 'auto' }}>
            <DataTable
              title={<TableHeader title="Referral History" />}
              columns={columns}
              data={referralData}
              pagination
              customStyles={customStyles}
            />
          </div>
        
        )}
      </Container>
    </>
  )
}

export default withTranslation()(ReferralHistory)
