

import React, { useRef, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { createcatlog ,updatecatlog ,getcatlog} from '../../apollo';
import { Button, Box, Typography, Grid, TextField,Alert, Dialog, DialogTitle, DialogContent, DialogActions,Switch,Snackbar } from '@mui/material';
import useStyles from './styles';
import useGlobalStyles from '../../utils/globalStyles';
import { v4 as uuidv4 } from 'uuid';


const CREATE_CATLOG = gql`
  ${createcatlog}
`;
const EDIT_CATLOG = gql`
  ${updatecatlog}
`;
const GET_CATLOG = gql`
  ${getcatlog}
`;

function Catlog(props) {
  const { onClose  } = props;
  const formRef = useRef();
  const mutation = props.vendor ? EDIT_CATLOG : CREATE_CATLOG;
  const [error, errorSetter] = useState('');
  const [success, successSetter] = useState('');
  const { t } = props;
  const [openSnackk, setopenSnackk] =useState(false);
  const inputTextStyle = { color: 'black' };
  const [formData, setFormData] = useState({
    name: props.vendor ? props.vendor.name : '',
    description: props.vendor ? props.vendor.description : '',
    isActive: props.vendor ? props.vendor.isActive : false,
    parcelable: props.vendor ? props.vendor.parcelable : false,
    id:props.vendor ? props.vendor._id : ''
  });

  const [formErrors, setFormErrors] = useState({});
  const uniqueId = uuidv4();
  const onCompleted = (data) => {
    if (!props.vendor) clearFields();
    const message = props.vendor ? t('Catlog Updated Successfully') : t('Catlog Added Successfully');
    errorSetter('');
    successSetter(message);
    setopenSnackk(true)
    props.refetch()
    setTimeout(hideAlert, 3000);
  };

  const onError = ({ graphQLErrors, networkError }) => {
   successSetter('');
    if (graphQLErrors) 
    {
     const error = graphQLErrors[0].message
      errorSetter(error);
      setopenSnackk(true)
      props.refetch()
    }
   
    else if (networkError) errorSetter(networkError.result.errors[0].message);
    else errorSetter('Something went wrong!');
    setTimeout(hideAlert, 3000);
    setopenSnackk(true)
  };
  const [mutate, { loading: mutateLoading }] = useMutation(mutation, {
    refetchQueries: [{ query: GET_CATLOG }],
    onError,
    onCompleted,
  });
  const clearFields = () => {
    setFormErrors({});
    formRef.current.reset();
    setFormData({
      name: props.vendor ? props.vendor.name : '',
      description: props.vendor ? props.vendor.description : '',
      isActive: props.vendor ? props.vendor.isActive : '',
      parcelable: props.vendor ? props.vendor.parcelable : '',
     id:props.vendor ? props.vendor._id : ''
    })
  };

  const hideAlert = () => {
    errorSetter('');
    successSetter('');
    setopenSnackk(false)
  };

  const classes = useStyles();
  const globalClasses = useGlobalStyles();


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update form data state
    setFormData({ ...formData, [name]: value });
    if (formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: '' });
    }
  };
  const validateFormData =()=>{
    let errors = {};
    if (!formData.name) errors.name = 'Catalog Name is required';
    if (!formData.description) errors.description = ' Catalog Discription  is required';
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }
  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const onSubmit = (e)=>{
    e.preventDefault();

    if (!validateFormData()){
      return;
    }

    if(true){
   
  ! props.vendor ?
      mutate({
      variables:{
        catalogInput: {
          description: formData.description,
          name: formData.name,
          parcelable: formData.parcelable,
          _id: uniqueId
        }
      }
    }) :
    mutate({
      variables:{
        catalogUpdate: {
          _id : formData.id ,
          description: formData.description,
          name: formData.name,
          isActive :formData.isActive ,
          parcelable: formData.parcelable
        }
      }
    });

    // Close the modal after 3 seconds by calling the parent's onClose callback
    setTimeout(() => {
      if (typeof props.onClose === 'function') {
        props.onClose(); // Close the modal
      }
    }, 1000);
  }
  }

  return (
    <Dialog open={true}  onClose={(event, reason) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    }} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box className={props.vendor ? classes.heading : classes.heading}>
          <Typography className={props.vendor ? classes.textWhite : classes.textWhite}>
            {props.vendor ? t('Edit Catlog') : t('Add Catalog ')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <form ref={formRef} onSubmit={onSubmit}>
          <Grid container spacing={2}>
            {!props.editModal ?
             <>
             <Grid item xs={12} sm={6}>
             <TextField name="name" label=" Name"                 helperText={formErrors.name}
                error={Boolean(formErrors.name)}  variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}/>  </Grid>
            <Grid item xs={12} sm={6}>
              <TextField name="description" 
            helperText={formErrors.description}
             error={Boolean(formErrors.description)} 
              label="Description" variant="outlined" fullWidth  onChange={handleInputChange} inputProps={{ style: inputTextStyle }}/>
            </Grid>
            <Grid item xs={12} sm={6}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" component="span" style={{ color: formData.isActive ? 'gray' : 'red' }}>
                  Status
                </Typography>
                <Switch name="isActive" checked={formData.isActive} onChange={handleSwitchChange} />
              </div>  </Grid>
            <Grid item xs={12} sm={6}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" component="span" style={{ color: formData.isActive ? 'gray' : 'red' }}>
                Parcelable
                </Typography>
                <Switch name="parcelable" checked={formData.parcelable} onChange={handleSwitchChange} />
              </div> </Grid>
           
           
           </>
              :
              ( // Conditionally render the second form fields based on editModal prop
              <>
             <Grid item xs={12} sm={6}>
             <TextField name="name" label=" Name"
                             helperText={formErrors.name}
                             error={Boolean(formErrors.name)} 
              value={formData.name} variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}/>  </Grid>
            <Grid item xs={12} sm={6}>
              <TextField name="description"
                              helperText={formErrors.description}
                              error={Boolean(formErrors.description)} 
               label="Description" value={formData.description} variant="outlined" fullWidth  onChange={handleInputChange} inputProps={{ style: inputTextStyle }}/>
            </Grid>
            <Grid item xs={12} sm={6}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" component="span" style={{ color: formData.isActive ? 'gray' : 'red' }}>
                  Status
                </Typography>
                <Switch name="isActive" checked={formData.isActive} onChange={handleSwitchChange} />
              </div>  </Grid>
            <Grid item xs={12} sm={6}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" component="span" style={{ color: formData.isActive ? 'gray' : 'red' }}>
                Parcelable
                </Typography>
                <Switch name="parcelable" checked={formData.parcelable} onChange={handleSwitchChange} />
              </div> </Grid>
           
           
           </>
           ) 
            }
          </Grid>
      <DialogActions>
        <Button 
        type='submit'
          className={globalClasses.dashbordBtn}
          disabled={mutateLoading}
        >
          {props.vendor ? t('Update') : t('Add')}
        </Button>
        <Button
          className={globalClasses.modalCancleBtn}
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
        </form>
      </DialogContent>
      

        <Box mt={2}>
        {success && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Dialog>
  );
}
export default withTranslation()(Catlog);
