import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client'
import Header from '../components/Headers/Header'
import CustomLoader from '../components/Loader/CustomLoader'
import { GetAllReferrals, DeleteEventReferrals } from '../apollo'
import AddReferral from '../components/AddReferral/AddReferral'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import { customStyles } from '../utils/tableForReferrals'
import { Icon } from '@iconify/react'
import useGlobalStyles from '../utils/globalStyles'
import {
  Container,
  Button,
  Box,
  Snackbar,
  Alert,
  Modal,
  IconButton,
  Paper,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import TableHeader from '../components/TableHeader'

// GraphQL Queries and Mutations
const GET_ALL_REFERRALS = gql`
  ${GetAllReferrals}
`

const DELETE_EVENT_REFERRAL = gql`
  ${DeleteEventReferrals}
`

const ManageReferral = props => {
  const { t } = props
  const globalClasses = useGlobalStyles()
  const client = useApolloClient()
  const [setData] = useState(null)
  const [editModal, setEditModal] = useState(false)
  const [addReferralModal, setAddReferralModal] = useState(false)
  const [selectedReferral, setSelectedReferral] = useState(null)
  const [openSnack, setOpenSnack] = useState(false)
  const [error, setError] = useState('')
  const [page, setPage] = useState(1)
  var [tableData, setTableData] = useState([])
  const [currentPage, setCurrentPage] = useState(page)
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [success, setSuccess] = useState('')
  const [filterModalOpen, setFilterModalOpen] = useState(false)

  const { loading: loadingQuery, data, refetch } = useQuery(GET_ALL_REFERRALS, {
    variables: { limit: perPage, skip: (page - 1) * perPage },
    fetchPolicy: 'network-only',

    // Set data state when query completes
    onCompleted: data => {
      if (data && data.getAllReferrals) {
        console.log('Fetched data:', data) // Debug log
        const tableData = data.getAllReferrals.referrals || []
        setTableData(tableData) // Set current page data
        setTotalRows(data.getAllReferrals.count || 0) // Set total count for pagination
      }
    },

    onError: error => {
      setError(error.message)
      setOpenSnack(true)
    }
  })

  const [DeleteEventReferrals] = useMutation(DELETE_EVENT_REFERRAL, {
    onError: error => {
      setError('Failed to delete referral.')
      setOpenSnack(true)
    },
    onCompleted: () => {
      setSuccess('Referral deleted successfully.')
      setOpenSnack(true)
      refetch()
    }
  })
  useEffect(() => {}, [data])
  const formatToIST = dateString => {
    if (!dateString) return '' // Handle null or undefined dates
    const date = new Date(dateString)
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
      //   hour: '2-digit',
      //   minute: '2-digit',
      //   second: '2-digit',
      //   timeZone: 'Asia/Kolkata',
    }
    return new Intl.DateTimeFormat('en-IN', options).format(date)
  }

  const handleAddReferral = () => {
    setSelectedReferral(null)
    setAddReferralModal(true)
  }

  const toggleModal = referral => {
    const transformedReferral = {
      code: referral.code,
      type: referral.type,
      isActive: referral.isActive,
      expiry: referral.expiry,
      refereeDiscountPecentage: referral.refereeDiscountPecentage, // Ensure it's named correctly
      referrerAmount: referral.referrerAmount,
      referrerPercent: referral.referrerPercent,
      referrerEventName: referral.referrerEventName,
      referredEventRegisterdCount: referral.referredEventRegisterdCount,
      referredEventAppliedCount: referral.referredEventAppliedCount,
      referredEventRedeemedCount: referral.referredEventRedeemedCount
    }
    setSelectedReferral(transformedReferral)
    setEditModal(true)
  }

  const closeAddReferralModal = () => setAddReferralModal(false)
  const closeEditModal = () => setEditModal(false)

  useEffect(() => {
    console.log('Refetching data for page:', page, ' with limit:', perPage) // Debug log
    refetch({
      variables: {
        limit: perPage,
        skip: (page - 1) * perPage // Ensure this calculation matches your server logic
      }
    })
  }, [page, perPage, refetch])

  useEffect(() => {
    console.log('Table data:', tableData) // Debug log
  }, [tableData])

  const handlePerRowChange = newPerPage => {
    setPerPage(newPerPage)
  }

  const handlePageChange = newPage => {
    console.log('Next page clicked, newPage:', newPage) // Debug log
    setPage(newPage) // Update state
  }

  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }

      return row[field]
    }

    return orderBy(rows, handleField, direction)
  }

  const columns = [
    { name: 'Sl No.', cell: (row, index) => index + 1 },
    { name: 'Referral Code', selector: row => row.code, sortable: true,wrap:true },
    {
      name: 'Type',
      selector: 'type',
      sortable: true,
      cell: row => (row.type === 0 ? 'Customer' : 'Event')
    },
    {
      name: 'Is Active',
      selector: 'isActive',
      sortable: true,
      cell: row => (row.isActive ? 'Active' : 'Inactive')
    },
    {
      name: 'Referee Discount',
      selector: row => row.refereeDiscountPecentage,
      sortable: true
    },
    { name: 'Referrer Amount', selector: 'referrerAmount', sortable: true },
    {
      name: 'Referrer (%)',
      selector: 'referrerPercent',
      sortable: true
    },

    { name: 'Event Name', selector: 'referrerEventName', sortable: true },

    {
      name: 'Registerd Count',
      selector: row => row.referredEventRegisterdCount || '0',
      sortable: true
    },
    {
      name: 'Applied Count',
      selector: row => row.referredEventAppliedCount || '0',
      sortable: true
    },
    {
      name: 'Redeemed Count',
      selector: row => row.referredEventRedeemedCount || '0',
      sortable: true
    },
    {
      name: 'Expiry Date',
      selector: row => formatToIST(row.expiry),

      sortable: true
    },

    {
      name: 'Action',
      cell: row => <>{actionButtons(row)}</>
    }
  ]

  const actionButtons = row => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = event => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }

    return (
      <>
        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            onClick={handleClick}>
            <MoreVertIcon fontSize="small" />
          </IconButton>
          <Paper>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button'
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}>
              <MenuItem
                onClick={e => {
                  e.preventDefault()
                  toggleModal(row)
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <EditIcon fontSize="small" style={{ color: 'green' }} />
                </ListItemIcon>
                <Typography color="green">{t('Edit')}</Typography>
              </MenuItem>
              <MenuItem
                onClick={e => {
                  e.preventDefault()
                  DeleteEventReferrals({ variables: { code: row.code } })
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" style={{ color: 'red' }} />
                </ListItemIcon>
                <Typography color="red">{t('Delete')}</Typography>
              </MenuItem>
            </Menu>
          </Paper>
        </div>
      </>
    )
  }

  return (
    <>
      <Header />
      <Container className={globalClasses.flex}>
        <Box sx={{ textAlign: 'right', mb: 3 }}>
          <Button
            className={globalClasses.dashbordBtn}
            onClick={handleAddReferral}>
            <Icon icon="ic:twotone-plus" style={{ color: 'white' }} />{' '}
            {t('Add New Referral')}
          </Button>
        </Box>
        {loadingQuery ? (
          <CustomLoader />
        ) : (
          <DataTable
            title={<TableHeader title={t('Manage Referrals')} />}
            columns={columns}
            data={tableData} // Current page data
            pagination
            paginationServer
            paginationTotalRows={totalRows} // Total from API
            paginationDefaultPage={page} // Current page
            onChangePage={handlePageChange} // Handles page change
            onChangeRowsPerPage={newPerPage => {
              setPerPage(newPerPage)
            }}
            customStyles={customStyles}
          />
        )}
        <Modal open={filterModalOpen} onClose={() => setFilterModalOpen(false)}>
          <Box>{/* Add Filter Modal Content Here */}</Box>
        </Modal>
        <Modal open={addReferralModal} onClose={closeAddReferralModal}>
          <AddReferral
            referralData={selectedReferral || {}}
            onClose={closeAddReferralModal}
            refetch={refetch}
          />
        </Modal>
        <Modal open={editModal} onClose={closeEditModal}>
          <AddReferral
            referralData={selectedReferral}
            onClose={closeEditModal}
            refetch={refetch}
            editModal={editModal}
          />
        </Modal>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={() => setOpenSnack(false)}>
          <Alert severity={error ? 'error' : 'success'}>
            {error || success}
          </Alert>
        </Snackbar>
      </Container>
    </>
  )
}

export default withTranslation()(ManageReferral)
