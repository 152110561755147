import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client'
import Header from '../components/Headers/Header'
import CustomLoader from '../components/Loader/CustomLoader'
import { getallcategories, deletecategory, SearchCategories } from '../apollo'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import { customStyles } from '../utils/tableforManageCategeory'
import useGlobalStyles from '../utils/globalStyles'
import {
  Container,
  Button,
  Grid,
  Modal,
  MenuItem,
  IconButton,
  Menu,
  ListItemIcon,
  Typography,
  Paper,
  useTheme, Box, Snackbar, Alert
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import TableHeader from '../components/TableHeader'
import AddCategory from '../components/AddCategory/AddCategory'
import { Icon } from '@iconify/react';
import AddSubCategory from '../components/AddSubCategory/AddSubCategory'
import SearchBar from '../components/TableHeader/SearchBar'

const GET_ALL_CATEGORIES = gql`
  ${getallcategories}
`
const DELETE_CATEGORY = gql`
  ${deletecategory}
`
const GET_SEARCH_CATEGORIES = gql`
  ${SearchCategories}
`
const ManageCategory = props => {
  const theme = useTheme();
  const { t } = props;
  const [editModal, setEditModal] = useState(false)
  const [addVendorModal, setAddVendorModal] = useState(false)
  const [addSubCategoryModal, setSubCategoryModal] = useState(false)
  const [vendors, setVendor] = useState(null)
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const client = useApolloClient();
  const golbalClasses = useGlobalStyles()
  const [searchQuery, setSearchQuery] = useState('')
  var [tabledata, setTabledata] = useState(null);
  const [relaod, setReload] = useState(false)
  const [currentPage, setCurrentPage] = useState(page);
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(
    1
  );
  const closeEditModal = () => {
    setEditModal(false);
    // setAddVendorModal(false);
  };
  const onClickRefetch = () => {
    refetch()
  
    setReload(!relaod)
 
  }
  const openaAddVendorModal = () => {
    setAddVendorModal(true);
  };

  const openaAddSubCategoryModal = () => {
    setSubCategoryModal(true);
  };
  const closeAddVendorModal = () => {
    setAddVendorModal(false);
  };

  const closeAddSubCategoryModal = () => {
    setSubCategoryModal(false);

  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const onChangeSearch = e => {
    setSearchQuery(e.target.value)
    setTimeout(() => {
      refetchSearch()
    }, 1000)

  }
  const variablesSearch = {
    text: searchQuery, // Assuming `text` is the variable used in your search query
    limit: perPage,
    skip: (page - 1) * perPage,
  };
  const { data: dataSearch, refetch: refetchSearch } = useQuery(
    GET_SEARCH_CATEGORIES, {
    variables: variablesSearch,

    onCompleted: (data) => {
      setTabledata(data && data.searchCategories.categories ? data.searchCategories.categories : [])
      setTotalRows(data.searchCatalogs.totalCount);
    },
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');

      if (isForbiddenError) {
        localStorage.removeItem('user-native');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  }
  )
  const { loading: loadingQuery, error: errorQuery, data, refetch } = useQuery(
    GET_ALL_CATEGORIES, {
      onCompleted: (data) => {
        setTabledata(data && data.getCategories ? data.getCategories : [])
        // setTotalRows(data.getCategories.totalCount);
      },
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');

      if (isForbiddenError) {
        localStorage.removeItem('user-native');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  }

  )
  const [mutate, { loading }] = useMutation(DELETE_CATEGORY, {
    refetchQueries: [{ query: GET_ALL_CATEGORIES }],
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');

      if (isForbiddenError) {
        localStorage.removeItem('user-native');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  })

  useEffect(() => {
    if (data) {
      var filtered =data.getCategories;
      setTabledata(filtered);
    }
  }, [data ,relaod]);
     

  const toggleModal = vendor => {
    setEditModal(!editModal)
    setVendor(vendor)
  }

  useEffect(() => {
    localStorage.removeItem('restaurant_id')
    localStorage.removeItem('catagery_id');
  }, [])

  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }

      return row[field]
    }

    return orderBy(rows, handleField, direction)
  }


  // Check if data is loading or if there's an error
  if (loadingQuery) return <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    }}>
    <CustomLoader />
  </div>; // Show loading spinner

  if (errorQuery) {
    return <span>{`Error! ${errorQuery.message}`}</span>; // Show error message
  }

  // Check if data exists and has the expected structure
  if (!data || !data.getCategories || !Array.isArray(data.getCategories)) {
    return <span>No data available or unexpected data structure.</span>;
  }

  const columns = [


    {
      name: "Sl No.",
      cell: (row, index) => <>{index + 1}</>,
      style: { cursor: 'pointer', minWidth: "200px" },
    },
    {
      name: "Category ID",
      sortable: true,
      selector: '_id',
      style: { cursor: 'pointer', minWidth: "200px" },
    },
    {
      name: "Category Name",
      sortable: true,
      selector: 'name',
      style: { cursor: 'pointer', minWidth: "200px" },
    },
    {
      name: "Description",
      sortable: true,
      selector: 'description',
      style: { cursor: 'pointer', minWidth: "150px" },
    },

    {
      name: t('Image'),
      cell: row => (
        <>
          {!!row.images && (
            <img
              className="img-responsive"
              src={row.images[0]}
              alt={t('ImageMenu')}
              style={{ width: 30, height: 30, cursor: 'pointer' }}
              onClick={() => {
                localStorage.setItem('catagery_id', row._id)
                props.history.push({
                  pathname: '/super_admin/mangesubcategory',
                  state: { id: row._id }
                })
              }}
            />
          )}
          {!row.images && 'No Image'}
        </>
      ),
      selector: 'images',
      
      style: { cursor: 'pointer', minWidth: "150px" },
    },

    {
      name: t('Action'),
      cell: row => <>{actionButtons(row)}</>
    }
  ]

  const actionButtons = row => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = event => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }
    return (
      <>
        <Button
          size="20px"
          variant="contained"
          sx={{
            fontWeight: 'bold',
            backgroundColor: theme.palette.warning.dark,
            color: theme.palette.common.white,
            padding: 0,
            height: '15px',
            fontSize: '7px',
            '&:hover': {
              border: '2px solid #637BFE',
              color: theme.palette.warning.dark,
              backgroundColor: "white"
            }
          }}
          onClick={e => {
            e.preventDefault()
            localStorage.setItem('catagery_id', row._id)
            localStorage.setItem('CategoryName', row.name)
            props.history.push({
              pathname: '/super_admin/mangesubcategory',
              state: { id: row._id, CategoryName: row.name }
            })
          }}>
          {t('View Sub Category')}
        </Button>
        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            onClick={handleClick}>
            <MoreVertIcon fontSize="small" />
          </IconButton>
          <Paper>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button'
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}>
              <MenuItem
                onClick={e => {
                  e.preventDefault()
                  setTimeout(() => {
                  }, 5000)
                  // uncomment this for paud version
                  toggleModal(row);
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <EditIcon fontSize="small" style={{ color: 'green' }} />
                </ListItemIcon>
                <Typography color="green">{t('Edit')}</Typography>
              </MenuItem>
              <MenuItem
                disabled
                onClick={e => {
                  e.preventDefault()
                  setTimeout(() => {
                  }, 5000)
                  // uncomment this for paud version
                  mutate({ variables: { categoryId: row._id } });
                }}
                style={{ height: 25 }}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" style={{ color: 'red' }} />
                </ListItemIcon>
                <Typography color="red">{t('Delete')}</Typography>
              </MenuItem>
            </Menu>
          </Paper>
        </div>
      </>
    )
  }


  return (
    <>
      <Header />
      <Container className={golbalClasses.flex}>
        <Box sx={{ textAlign: 'right', mb: 3 }}>
          <Button
            className={golbalClasses.dashbordBtn}
            onClick={openaAddVendorModal}
          >
            <Icon icon="ic:twotone-plus" style={{ color: "white" }} />  {t('AddNewCategory')}
          </Button>
          <Button
            className={golbalClasses.dashbordBtn}
            onClick={openaAddSubCategoryModal}
          >
            <Icon icon="ic:twotone-plus" style={{ color: "white" }} />  {t('Add New Sub Category')}
          </Button>
        </Box>

        {addVendorModal ?
          <Grid container>
            <Grid item xs={12} order={{ xs: 2, lg: 1 }}>
              <Grid
                sx={{ display: { xs: 'none', lg: 'block' } }}
                item
                mt={5}
                ml={-2}
                order={{ xs: 1, lg: 2 }}
              >
                <Modal
                  open={addVendorModal}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  onClose={closeAddVendorModal}
                >
                  <AddCategory onClose={closeAddVendorModal} refetch={refetch} />
                  {/* <VendorComponent onClose={closeAddVendorModal} /> */}
                </Modal>
              </Grid>
            </Grid>
          </Grid>
          : null
        }

        {addSubCategoryModal ?
          <Grid container>
            <Grid item xs={12} order={{ xs: 2, lg: 1 }}>
              <Grid
                sx={{ display: { xs: 'none', lg: 'block' } }}
                item
                mt={5}
                ml={-2}
                order={{ xs: 1, lg: 2 }}
              >
                <Modal
                  open={addSubCategoryModal}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  onClose={closeAddSubCategoryModal}
                >
                  <AddSubCategory onClose={closeAddSubCategoryModal} refetch={refetch} />
                  {/* <VendorComponent onClose={closeAddVendorModal} /> */}
                </Modal>
              </Grid>
            </Grid>
          </Grid>
          : null
        }

        {errorQuery & errorQuery ? <span> `Error! ${errorQuery.message}`</span> : null}
        {loadingQuery ? (
          <CustomLoader />
        ) : (
          <DataTable
            subHeader={true}
            subHeaderComponent={
              <>
                <SearchBar
                  value={searchQuery}
                  onChange={onChangeSearch}
                  onClick={() => onClickRefetch(refetch)}
                />
               
              </>
            }
            title={<TableHeader title={t('Manage Category')} />}
            columns={columns}
            data={tabledata || []}
            pagination
            progressPending={loading}
            progressComponent={<CustomLoader />}
            sortFunction={customSort}
            customStyles={customStyles}
            paginationTotalRows={totalRows}
            // selectableRows
            onRowClicked={row => {
              localStorage.setItem('restaurantId', row._id)
              localStorage.setItem('restaurantImage', row.image)
              localStorage.setItem('CategoryName', row.name)
            }}
          />
        )}
        <Modal
          open={editModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClose={() => {
            toggleModal()
          }}>
          <AddCategory vendor={vendors} onClose={closeEditModal} refetch={refetch} />
          {/* <VendorComponent vendor={vendors} onClose={closeEditModal} /> */}
        </Modal>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000} // Adjust as needed
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleSnackbarClose} severity="success">
            Deleted Successfully!
          </Alert>
        </Snackbar>
      </Container>
    </>
  )
}

export default withTranslation()(ManageCategory)
