import React, { useRef, useState, useEffect } from 'react'
import { useMutation, gql } from '@apollo/client'
import { withTranslation } from 'react-i18next'
import {
  CreateEventReferrals,
  UpdateEventReferrals,
  CreateCustomerReferrals,
  UpdateCustomerReferrals
} from '../../apollo'
import {
  Button,
  Box,
  Typography,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Snackbar,
  Autocomplete,
  Paper,
  Switch,
  FormControlLabel
} from '@mui/material'
import useStyles from './styles'
import useGlobalStyles from '../../utils/globalStyles'

const CREATE_EVENT_REFERRAL = gql`
  ${CreateEventReferrals}
`
const CREATE_CUSTOMER_REFERRAL = gql`
  ${CreateCustomerReferrals}
`
const EDIT_CUSTOMER_REFERRAL = gql`
  ${UpdateCustomerReferrals}
`
const EDIT_EVENT_REFERRAL = gql`
  ${UpdateEventReferrals}
`

const CustomPaperComponent = props => (
  <Paper {...props} style={{ background: 'white', color: 'black' }} />
)
function AddReferral(props) {
  const { onClose, editModal, referralData } = props
  const formRef = useRef()

  const [formData, setFormData] = useState(() => {
    if (editModal && referralData) {
      return {
        code: referralData.code || '',
        expiry: referralData.expiry || '',
        //expiry: referralData.expiry ? referralData.expiry.split('T')[0] : '',

        isActive: referralData.isActive || false,
        type: referralData.type || 0,
        refereeDiscountPecentage: referralData.refereeDiscountPecentage || '',
        referrerAmount: referralData.referrerAmount || '',
        referrerPercent: referralData.referrerPercent || '',
        referrerEventName: referralData.referrerEventName || ''
      }
    } else {
      return {
        code: '',
        expiry: '',
        isActive: false,
        type: 0,
        refereeDiscountPecentage: '',
        referrerAmount: '',
        referrerPercent: '',
        referrerEventName: ''
      }
    }
  })

  console.log(formData, 'formData')
  const mutation = props.editModal
    ? formData.type === 1
      ? EDIT_EVENT_REFERRAL
      : EDIT_CUSTOMER_REFERRAL
    : formData.type === 1
    ? CREATE_EVENT_REFERRAL
    : CREATE_CUSTOMER_REFERRAL

  console.log(props.isEvent, 'isEvent-->')
  const [executeMutation] = useMutation(mutation)
  let [error, errorSetter] = useState('')
  // const [error, errorSetter] = useState('');
  const [success, successSetter] = useState('')
  const [openSnackk, setopenSnackk] = useState(false)
  const [showCustomerFields, setShowCustomerFields] = useState(false)
  const [showEventFields, setShowEventFields] = useState(false)
  const { t } = props
  //const [endDateError, setEndDateError] = useState('')
  const [expiryDateError, setExpiryDateError] = useState('')
  const userType = JSON.parse(localStorage.getItem('user-enatega'))

  const formatToYYYYMMDD = dateString => {
    if (!dateString) return ''
    const date = new Date(dateString)
    return date.toISOString().split('T')[0] // Convert to "YYYY-MM-DD" format
  }
  const handleSwitchChange = e => {
    const { name, checked } = e.target
    setFormData({ ...formData, [name]: checked })
  }

  const [StartDate, setStartDate] = useState(formatToYYYYMMDD(formData.start))
  //const [EndDate, setEndDate] = useState(formatToYYYYMMDD(formData.end))
  const [ExpiryDate, setExpiryDate] = useState(
    formatToYYYYMMDD(formData.expiry)
  )
  const formatDateToISO = dateString => {
    const date = new Date(dateString)
    const isoString = date.toISOString() // This will give you the date in "2024-09-04T00:00:00.000Z" format
    return isoString.replace('Z', '+00:00') // Replacing 'Z' with '+00:00' to match your required format
  }
  const typeOptions = [
    { label: 'CUSTOMER', value: 0 },
    { label: 'EVENT', value: 1 }
  ]
  const [formErrors, setFormErrors] = useState({})
  const handleExpiryDateChange = value => {
    const newExpiryDate = value.target.value
    if (newExpiryDate < StartDate) {
      setFormErrors({
        ...formErrors,
        expiryDate: 'Expiry date cannot be earlier than the start date.'
      })
    } else {
      setFormErrors({ ...formErrors, expiryDate: '' })
      setExpiryDate(newExpiryDate)
      const formattedExpiryDate = formatDateToISO(newExpiryDate)
      setFormData({ ...formData, expiry: formattedExpiryDate })
    }
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }))
  }

  const handleTypeChange = (event, value) => {
    setFormData({
      ...formData,
      type: value ? value.value : 0 // Default to 'Customer' if no value is selected
    })
  }

  const onCompleted = data => {
    if (!props.editModal) clearFields()
    const message = props.editModal
      ? t('Referral Updated Successfully')
      : t('Referral Added Successfully')
    errorSetter('')
    successSetter(message)
    setopenSnackk(true)
    props.refetch()
    setTimeout(hideAlert, 3000)
  }

  const onError = ({ graphQLErrors, networkError }) => {
    successSetter('')

    if (graphQLErrors && graphQLErrors.length > 0) {
      errorSetter(graphQLErrors[0].message || 'An error occurred')
    } else if (
      networkError &&
      networkError.result &&
      networkError.result.errors &&
      networkError.result.errors.length > 0
    ) {
      errorSetter(
        networkError.result.errors[0].message || 'A network error occurred'
      )
    } else {
      errorSetter('Something went wrong!')
    }

    setopenSnackk(true)
    setTimeout(hideAlert, 3000)
  }

  const [mutate, { loading: mutateLoading }] = useMutation(mutation, {
    // refetchQueries: [{ query: GET_CENTRAL_STORE }],
    onError,
    onCompleted
  })

  const clearFields = () => {
    formRef.current.reset()
    setFormErrors({})

    setFormData({
      code: referralData.code || '',
      expiry: referralData.expiry || '',
      isActive: referralData.isActive || false,
      type: referralData.type || '',
      refereeDiscountPecentage: referralData.refereeDiscountPecentage || '',
      referrerAmount: referralData.referrerAmount || '',
      referrerPercent: referralData.referrerPercent || '',
      referrerEventName: referralData.referrerEventName || ''
    })
  }

  const hideAlert = () => {
    errorSetter('')
    successSetter('')
    setopenSnackk(false)
  }
  const handleToggle = () => {
    setFormData(prevState => ({
      ...prevState,
      isActive: !prevState.isActive
    }))
  }

  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const inputTextStyle = { color: 'black' }
  const onSubmit = event => {
    event.preventDefault()

    const mutationVariables = {
      // ... other variables
      ...(formData.type === 1
        ? {
            eventReferralInput: {
              code: formData.code || null, // Set to null if not applicable
              expiry: formData.expiry,
              isActive: formData.isActive,
              refereeDiscountPecentage:
                parseFloat(formData.refereeDiscountPecentage) || 0, // Handle non-numeric input
              referrerEventName: formData.referrerEventName || null // Set to null if not applicable
            }
          }
        : {
            referralInput: {
              expiry: formData.expiry,
              isActive: formData.isActive,
              refereeDiscountPecentage:
                parseFloat(formData.refereeDiscountPecentage) || 0,
              referrerAmount: parseFloat(formData.referrerAmount) || 0,
              referrerPercent: parseFloat(formData.referrerPercent) || 0
            }
          })
    }

    mutate({ variables: mutationVariables })
    setTimeout(() => {
      if (typeof props.onClose === 'function') {
        props.onClose() // Close the modal
      }
    }, 2000)
  }

  return (
    <Dialog
      open={true}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose()
        }
      }}
      maxWidth="sm"
      fullWidth>
      <DialogTitle>
        <Box className={props.vendor ? classes.heading : classes.heading}>
          <Typography
            className={props.vendor ? classes.textWhite : classes.textWhite}>
            {props.editModal ? t('Edit Referral') : t('Add Referral')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <form ref={formRef} onSubmit={onSubmit}>
          <Grid container spacing={2}>
            {!props.editModal ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    options={typeOptions}
                    getOptionLabel={option => option.label}
                    value={
                      typeOptions.find(
                        option => option.value === formData.type
                      ) || typeOptions.find(option => option.value === 0)
                    }
                    onChange={handleTypeChange}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Type"
                        variant="outlined"
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          style: inputTextStyle
                        }}
                        error={Boolean(formErrors.type)}
                        helperText={formErrors.type}
                      />
                    )}
                    PaperComponent={CustomPaperComponent}
                  />
                </Grid>
                {formData.type === 1 && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="code"
                      label="Referral code"
                      variant="outlined"
                      fullWidth
                      value={formData.code}
                      onChange={handleInputChange}
                      inputProps={{ style: inputTextStyle }}
                      error={Boolean(formErrors.code)}
                      helperText={formErrors.code}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="expiry"
                    label="Expiry"
                    variant="outlined"
                    fullWidth
                    type="date"
                    value={ExpiryDate}
                    onChange={handleExpiryDateChange}
                    InputLabelProps={{ shrink: true }}
                    required
                    InputProps={{ style: inputTextStyle }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="refereeDiscountPecentage"
                    label="Referee Discount Pecentage"
                    variant="outlined"
                    fullWidth
                    value={formData.refereeDiscountPecentage}
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                    error={Boolean(formErrors.refereeDiscountPecentage)}
                    helperText={formErrors.refereeDiscountPecentage}
                  />
                </Grid>
                {formData.type === 1 && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="referrerEventName"
                      label="Referrer Event Name"
                      variant="outlined"
                      fullWidth
                      value={formData.referrerEventName}
                      onChange={handleInputChange}
                      inputProps={{ style: inputTextStyle }}
                      error={Boolean(formErrors.referrerEventName)}
                      helperText={formErrors.referrerEventName}
                    />
                  </Grid>
                )}

                {formData.type === 0 && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="referrerAmount"
                      label="Referrer Amount"
                      variant="outlined"
                      fullWidth
                      value={formData.referrerAmount}
                      onChange={handleInputChange}
                      inputProps={{ style: inputTextStyle }}
                      error={Boolean(formErrors.referrerAmount)}
                      helperText={formErrors.referrerAmount}
                    />
                  </Grid>
                )}

                {formData.type === 0 && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="referrerPercent"
                      label="Referrer Percent"
                      variant="outlined"
                      fullWidth
                      value={formData.referrerPercent}
                      onChange={handleInputChange}
                      inputProps={{ style: inputTextStyle }}
                      error={Boolean(formErrors.referrerPercent)}
                      helperText={formErrors.referrerPercent}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      variant="body1"
                      component="span"
                      style={{ color: formData.isActive ? 'gray' : 'red' }}>
                      Active
                    </Typography>
                    <Switch
                      name="isActive"
                      checked={formData.isActive}
                      onChange={handleSwitchChange}
                    />
                  </div>
                </Grid>
              </>
            ) : (
              // Conditionally render the second form fields based on editModal prop
              <>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    options={typeOptions}
                    getOptionLabel={option => option.label}
                    value={
                      typeOptions.find(
                        option => option.value === formData.type
                      ) || typeOptions.find(option => option.value === 0)
                    }
                    disabled={typeOptions}
                    onChange={handleTypeChange}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Type"
                        variant="outlined"
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          style: inputTextStyle
                        }}
                        error={Boolean(formErrors.type)}
                        helperText={formErrors.type}
                      />
                    )}
                    PaperComponent={CustomPaperComponent}
                  />
                </Grid>
                {formData.type === 1 && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="code"
                      label="Referral code"
                      variant="outlined"
                      fullWidth
                      value={formData.code}
                      onChange={handleInputChange}
                      inputProps={{ style: inputTextStyle }}
                      error={Boolean(formErrors.code)}
                      helperText={formErrors.code}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="expiry"
                    label="Expiry"
                    variant="outlined"
                    fullWidth
                    type="date"
                    value={ExpiryDate}
                    onChange={handleExpiryDateChange}
                    InputLabelProps={{ shrink: true }}
                    required
                    InputProps={{ style: inputTextStyle }}
                  />
                </Grid>
                {formData.type === 1 && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="referrerEventName"
                      label="Referrer Event Name"
                      variant="outlined"
                      fullWidth
                      value={formData.referrerEventName}
                      onChange={handleInputChange}
                      inputProps={{ style: inputTextStyle }}
                      error={Boolean(formErrors.referrerEventName)}
                      helperText={formErrors.referrerEventName}
                    />
                  </Grid>
                )}

                <Grid item xs={12} sm={6}>
                  <TextField
                    name="refereeDiscountPecentage"
                    label="Referee Discount Pecentage"
                    variant="outlined"
                    fullWidth
                    value={formData.refereeDiscountPecentage}
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                    error={Boolean(formErrors.refereeDiscountPecentage)}
                    helperText={formErrors.refereeDiscountPecentage}
                  />
                </Grid>
                {formData.type === 0 && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="referrerAmount"
                      label="Referrer Amount"
                      variant="outlined"
                      fullWidth
                      value={formData.referrerAmount}
                      onChange={handleInputChange}
                      inputProps={{ style: inputTextStyle }}
                      error={Boolean(formErrors.referrerAmount)}
                      helperText={formErrors.referrerAmount}
                    />
                  </Grid>
                )}
                {formData.type === 0 && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="referrerPercent"
                      label="Referrer Percent"
                      variant="outlined"
                      fullWidth
                      value={formData.referrerPercent}
                      onChange={handleInputChange}
                      inputProps={{ style: inputTextStyle }}
                      error={Boolean(formErrors.referrerPercent)}
                      helperText={formErrors.referrerPercent}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      variant="body1"
                      component="span"
                      style={{ color: formData.isActive ? 'gray' : 'red' }}>
                      Active
                    </Typography>
                    <Switch
                      name="isActive"
                      checked={formData.isActive}
                      onChange={handleSwitchChange}
                    />
                  </div>
                </Grid>
              </>
            )}
          </Grid>
          <DialogActions>
            <Button
              className={globalClasses.dashbordBtn}
              disabled={mutateLoading}
              type="submit">
              {props.editModal ? t('Update') : t('Add')}
            </Button>
            <Button
              className={globalClasses.modalCancleBtn}
              onClick={() => {
                onClose()
              }}>
              Cancel
            </Button>
          </DialogActions>
        </form>
      </DialogContent>

      <Box mt={2}>
        {success && (
          <Snackbar
            open={openSnackk}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar
            open={openSnackk}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Dialog>
  )
}
export default withTranslation()(AddReferral)
